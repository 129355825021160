import React, {useState, useEffect} from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import MobsortOptions from "./mobSortOptions";
import { FaSortAmountDown } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { SortOptionsType } from "../../types/commonTypes";

interface sortPanelMobileProps {
    sortOptions: SortOptionsType[],
    selectedSortOption: SortOptionsType | undefined,
    router?:{},
    currPath?:string
}


function SortPanelMobile(props:sortPanelMobileProps){
    const {sortOptions, selectedSortOption} = props;
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const [sortButtonToggle, setSortButtonToggle] = useState(false);
    const [selectedItem,setSelectedItem] = useState<SortOptionsType | undefined>();
    
    useEffect(() => {
        setSelectedItem(selectedSortOption);
    },[selectedSortOption]);

    const onSortButtonToggle = () => {
        const sortPanelWrapper =  document.getElementsByClassName('sort-panel-wrapper')[0] as HTMLElement
        if (sortButtonToggle) {
          document.body.classList.remove('overflow-hidden');
          sortPanelWrapper.style.display = 'none';
          setSortButtonToggle(false);
        } else {
          document.body.classList.add('overflow-hidden');
          sortPanelWrapper.style.display = 'block';
          setSortButtonToggle(true);
        }
    }
    const onChangeSorting = (event:React.ChangeEvent<HTMLInputElement>) => {
        const selectedItem1 = sortOptions.find(
          (item) => item.id === event.target.value,
        );
        setSelectedItem(selectedItem1);
        params.set('sort_by', selectedItem1?.sort_by!);
        params.set('sort_order', selectedItem1?.sort_order!);
        params.set('page','1');
    };
    const clearAll = () => {
        params.delete('sort_by');
        params.delete('sort_order');
        setSelectedItem(undefined);
        applySort();
    }
    const applySort = () => {
        navigate({ search: params.toString() });
        onSortButtonToggle();
    }
    return(
        <>
        <div className='sort-btn-mobile search-resul-mob-btn' data-testid="sortPanelMobile">
            <button type="button" className="" onClick={ () => onSortButtonToggle() }>
                <FaSortAmountDown className="mob-sort-icon"/>
                <span className="mx-2">{(selectedItem == undefined) ? 'Sort' : selectedItem.display_name}</span>
            </button>
        </div>
        <div className='sort-panel-wrapper'>
            <div className='sort-panel-container'>
                <h2 className="facet-header" data-testid="mob-sort-title">Sort</h2>
                <button type='button' className='close-mob-filter' onClick={onSortButtonToggle} data-testid="mob-close-sort"><MdClose /></button>
                <MobsortOptions sortOptions={ sortOptions } onChangeSorting={onChangeSorting} selectedItem={selectedItem}/>
                <div className='apply-sort-mob'>
                    <button type='button' onClick={applySort} data-testid="mob-apply-sort">Apply</button>
                    <button type='button' onClick={clearAll} data-testid="mob-clear-sort">Clear All</button>
                </div>
            </div>
        </div>
        </>
    )

}

export default SortPanelMobile;