import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortOptionsType } from '../../types/commonTypes';

interface sortOptionsProp {
  sortOptions:SortOptionsType[],
  setSelectedSortOption: React.Dispatch<React.SetStateAction<SortOptionsType|undefined>>,
  router?:{},
  currPath?:string
}

function SortOptions(props:sortOptionsProp) {
  const { sortOptions, setSelectedSortOption } = props;
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const selectedSort = `${params.get('sort_by')}_${params.get('sort_order')}`;

  const onChangeSorting = (event:React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = sortOptions.find(
      (item) => item.id === event.target.value,
    );
    const $select = document.querySelector('#sortSelect') as HTMLSelectElement;
    $select.value = selectedItem?.id!;
    setSelectedSortOption(selectedItem); 
    params.set('sort_by', selectedItem?.sort_by!);
    params.set('sort_order', selectedItem?.sort_order!);
    params.set('page','1');
    $select.blur();
    navigate({ search: params.toString() });
  };

  if (sortOptions?.length > 0) {
    return (
      <select
        className="form-select"
        id="sortSelect"
        onChange={ onChangeSorting }
        value={ selectedSort }
        data-testid="sortOptions"
      >
        {sortOptions.map((sortOption) => (
          <option key={ sortOption.id } value={ sortOption.id } data-testid="sort-option">
            {sortOption.display_name}
          </option>
        ))}
      </select>
    );
  }
  return null;
}

export default SortOptions;
