import React, {useEffect} from 'react';

function Footer(props:any) {
  useEffect(()=>{
    let elm = document.getElementById('replace-main-footer-container');
        if (elm != null && props.dataHtml != "") {
            elm.outerHTML = props.dataHtml;
        }
  })
  return (
    <div id='replace-main-footer-container'></div>
  );
}
export default Footer;