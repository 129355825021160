/* eslint-disable import/prefer-default-export */
import cioClient from '../cioClient';
import { ParametersType } from '../types/commonTypes';


interface searchResultsParameters {
  parameters: ParametersType,
  query: undefined | string,
}

export function parseUrlParameters() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchResultsParameters: searchResultsParameters = {
    parameters: {
      filters: {},
      fmtOptions: {},
      page: 1,
      numResultsPerPage: null,
      sortBy: null,
      sortOrder: null,
    },
    query: '',
  };

  // eslint-disable-next-line
  for (let [key, value] of urlSearchParams) {
    // key is a query
    if (key === 'pq') {
      if (value.length > 13 && (/^978|979/.test(value)) && value.includes("-")) {
        value = value.replaceAll("-", "");
      }
      searchResultsParameters.query = value;
    }
    if (key === 'sort_by') {
      searchResultsParameters.parameters.sortBy = value;
    }
    if (key === 'sort_order') {
      searchResultsParameters.parameters.sortOrder = value;
    }
    if (key === 'num_results_per_page') {
      searchResultsParameters.parameters.numResultsPerPage = Number(value);
    }
    if (key === 'page') {
      searchResultsParameters.parameters.page = Number(value);
    }
    // key is a filter
    const filterMatch = key.match(/^filters\[(\w+)\]$/);

    if (filterMatch?.length) {
      searchResultsParameters.parameters.filters[filterMatch?.[1]] = value.split('|');
    }
    
    // key is category
    if (key === 'group_id') {
      searchResultsParameters.parameters.filters.group_id = value;
    }
  }

  return searchResultsParameters;
}

// const encodeToHTMLEntities = (query: string | null) => {
//   return query?.replace(/[\u00A0-\u9999<>\&]/g, ((i) => `&#${i.charCodeAt(0)};`));
// };

export const fetchProducts = async (numResultsPerPage: number, country: string) => {
  const { query, parameters } = parseUrlParameters();
  parameters.filters["lifeCycleStatus_" + country] = "DISCONTINUED";
  parameters.filters["market_restriction"] = country;
  parameters.variationsMap = {
    "group_by": [
      {
        "name": "marketRestriction",
        "field": "data.market_restriction_" + country
      },
      {
        "name": "lifeCycle",
        "field": "data.lifeCycleStatus_" + country
      }
    ],
    "values": {
      "purchaseOption_group": {
        "aggregation": "all",
        "field": "data.purchaseOption_group"
      },
      "price": {
        "aggregation": "min",
        "field": "data.price_" + country
      },
      "publish_date": {
        "aggregation": "min",
        "field": "data.publish_date_display_" + country
      }
    },
    "dtype": "object"
  };
  parameters.fmtOptions["hidden_fields"] = ["lifeCycleStatus_" + country, "market_restriction_" + country];
  const response = await cioClient.search.getSearchResults(
    query,
    { ...parameters, resultsPerPage: numResultsPerPage },
  );

  return response.response;
};

export const fetchBrowseResults = async (filterName: string, filterValue: string, numResultsPerPage: number, country: string) => {
  const { parameters } = parseUrlParameters();
  parameters.filters["lifeCycleStatus_" + country] = "DISCONTINUED";
  parameters.filters["market_restriction"] = country;
  parameters.variationsMap = {
    "group_by": [
      {
        "name": "marketRestriction",
        "field": "data.market_restriction_" + country
      },
      {
        "name": "lifeCycle",
        "field": "data.lifeCycleStatus_" + country
      }
    ],
    "values": {
      "purchaseOption_group": {
        "aggregation": "all",
        "field": "data.purchaseOption_group"
      },
      "price": {
        "aggregation": "min",
        "field": "data.price_" + country
      },
      "publish_date": {
        "aggregation": "min",
        "field": "data.publish_date_display_" + country
      }
    },
    "dtype": "object"
  };
  parameters.fmtOptions["hidden_fields"] = ["lifeCycleStatus_" + country, "market_restriction_" + country];
  const response = await cioClient.browse.getBrowseResults(filterName, filterValue,
    { ...parameters, resultsPerPage: numResultsPerPage },
  );

  return response.response;
};

export const loadMoreBrowsedProducts = async (filterName: string, filterValue: string, currentPage: number, numResultsPerPage: number, totalResults: number, country: string) => {
  if (numResultsPerPage * currentPage >= totalResults) {
    return false;
  }
  const { parameters } = parseUrlParameters();
  parameters.filters["lifeCycleStatus_" + country] = "DISCONTINUED";
  parameters.filters["market_restriction"] = country;
  parameters.variationsMap = {
    "group_by": [
      {
        "name": "marketRestriction",
        "field": "data.market_restriction_" + country
      },
      {
        "name": "lifeCycle",
        "field": "data.lifeCycleStatus_" + country
      }
    ],
    "values": {
      "purchaseOption_group": {
        "aggregation": "all",
        "field": "data.purchaseOption_group"
      },
      "price": {
        "aggregation": "min",
        "field": "data.price_" + country
      },
      "publish_date": {
        "aggregation": "min",
        "field": "data.publish_date_display_" + country
      }
    },
    "dtype": "object"
  };
  parameters.fmtOptions["hidden_fields"] = ["lifeCycleStatus_" + country, "market_restriction_" + country];
  const response = await cioClient.browse.getBrowseResults(filterName, filterValue,
    { ...parameters, page: currentPage + 1, resultsPerPage: numResultsPerPage },
  );

  return response.response;
};