import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from '../Header';
import Footer from '../Footer';
import Breadcrumbs from '../Breadcrumbs';
import SearchResultPage from './SearchResultPage';
import { getLocale, getCountryFromLocale, getQueryParam, LoadExternalScript, properties, LoadInlineScript } from '../common/properties';
const MarketingSlot = React.lazy(() => import('../components/marketingSlot'));

function usePrevLocale() {
  const ref = useRef(localStorage.getItem('wileyEncodedLocale'));
  const prevLocale = ref.current;
  ref.current = getLocale();
  return prevLocale;
}

function App() {
  let currLocale = getLocale();

  const [headerData, setHeaderData] = useState({
    header: '',
    footer: '',
    headerScript: []
  });

  useEffect(() => {
    localStorage.setItem('wileyEncodedLocale', currLocale);
    let country = getCountryFromLocale().toLocaleLowerCase();
    window.digitalData = {
      'event': 'digitalData loaded',
      'environment': process.env.REACT_APP_AdobeAnalyticsMode,
      'formName': '',
      'page': {
        'pageName': '',
        'section': '',
        'type': ''
      },
      'site': {
        'country': country,
        'language': currLocale,
        'platform': 'web'
      }
    }
  }, []);

  const [nodeList, setNodeList] = useState<Element[]>([]);
  useEffect(() => {
    fetchHtml();
    let locURL = currLocale.replace('-', '/');
    let marketingURL = properties.marketingSlotURL + locURL;
    fetch(marketingURL)
      .then(r => r.text())
      .then(html => {

        let baseURL = process.env.REACT_APP_AEM_RESOURCE_BASE_URL as string;
        var parser = new DOMParser();
        var doc = parser.parseFromString(html, 'text/html');
        let v = doc.querySelectorAll('div.marketing-slot-item');
        let marketingSlotScript = doc.querySelector("script:last-child");
        let mSlotScript = baseURL + marketingSlotScript?.getAttribute('src') as string;
        if (v.length > 0) {
          setNodeList([...v]);
          LoadExternalScript(mSlotScript);
        }
      });
    require('bootstrap/dist/js/bootstrap');
  }, []);

  // fetch header footer through API
  const contryLocalArr = currLocale.split("-");
  const fetchHtml = () => {
    const headerFooterApi = properties.headerFooterURL.replace('{country}', contryLocalArr[1]).replace('{lang}', contryLocalArr[0]);
    fetch(headerFooterApi)
      .then(r => r.text())
      .then(response => {
        var parser = new DOMParser();
        var data = parser.parseFromString(response, "text/html");

        const headerData = data.querySelector('div#wiley-shared-global-header');
        const footerData = data.querySelector('div#wiley-shared-global-footer');
        const headerScript: any = data.querySelectorAll('script');
        if (headerData != null && footerData != null) {
          setHeaderData({
            header: headerData?.outerHTML as string,
            footer: footerData?.outerHTML as string,
            headerScript: headerScript
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  let wileyLocale = getLocale();
  if (wileyLocale == null) {
    wileyLocale = properties.getDefaultLocale;
  }
  const prevLocale = usePrevLocale();
  const crumbs = [{ "name": "Home", "path": "/" + wileyLocale + "/" }, { "name": "Search Results", "path": "/" + wileyLocale + "/search" }];
  const searchTerm = getQueryParam();

  if (prevLocale != null && wileyLocale != prevLocale) {
    var newUrl = window.location.origin + window.location.pathname + "?pq=" + encodeURIComponent(searchTerm ?? "");
    window.location.href = newUrl;
  }

  if (searchTerm != null && searchTerm.match(/\|/) && !/\|\s/gm.test(searchTerm)) {
    let arr = searchTerm.split("|");
    let newSearchTerm = arr[0];
    var newUrl1 = window.location.origin + window.location.pathname + "?pq=" + newSearchTerm;
    window.location.href = newUrl1;
  }

  return (
    <Router>
      <MarketingSlot node={nodeList[0]} id="banner-1" />
      <Header dataHtml={headerData.header} headerScript={headerData.headerScript} />
      <div className='main-page-container'>
        <MarketingSlot node={nodeList[1]} id="banner-2" />
        <MarketingSlot node={nodeList[2]} id="banner-3" />
        <MarketingSlot node={nodeList[3]} id="banner-4" />
        <Breadcrumbs crumbs={crumbs} />
        <Routes>
          {wileyLocale != "" && <Route path="/en-:any/search" element={<SearchResultPage />} />}
        </Routes>
      </div>
      <MarketingSlot node={nodeList[4]} id="banner-5" />
      <Footer dataHtml={headerData.footer} />
      <MarketingSlot node={nodeList[5]} id="banner-6" />
    </Router>
  );
}

export default App;
