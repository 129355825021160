import { useEffect } from 'react';
import { LoadExternalScript, LoadInlineScript } from './common/properties';

type headerProps = {
  dataHtml: string
  headerScript: any
}

function Header({dataHtml, headerScript}: headerProps) {
  useEffect(() => {
    let elm = document.getElementById('replace-main-header-container');

    if (elm != null && dataHtml != "") {
      elm.outerHTML = dataHtml;
      
      headerScript.forEach((script:any) => {
        let scriptSrc = script.getAttribute('src') as string;
        scriptSrc ? LoadExternalScript(scriptSrc.startsWith("https") ? scriptSrc : process.env.REACT_APP_AEM_RESOURCE_BASE_URL + scriptSrc) : LoadInlineScript(script.innerHTML);
      })
    }
  })
  useEffect(() => {
    setTimeout(() => {
      const marketingSlot1 = document.querySelector<HTMLElement>('#banner-1');
      const sharedHeader = document.querySelector<HTMLElement>('.wl-header__wrapper');

      if (marketingSlot1?.innerHTML && sharedHeader) {
        sharedHeader.style.top = marketingSlot1.offsetHeight + 'px';
        window.addEventListener('scroll', function () {
          if (window.scrollY > 0) {
            sharedHeader.style.top = 0 + 'px';
          } else {
            sharedHeader.style.top = marketingSlot1.offsetHeight + 'px';
          }
        });
      }
    }, 1500)
    const checkClear = () => {
      clearInterval(check);
    }

    const checkTrigger = () => {
      const searchbox = document.querySelector<HTMLElement>('.searchboxContainer');
      const searchBoxWraper = document.querySelector<HTMLElement>('#search-container');
      const searchBoxJS = document.getElementById('searchLibrary');

      if (searchbox) {
        checkClear();
      }
      else if (searchBoxWraper && searchBoxJS) {
        try{
        window.renderSearchBox();
        checkClear();
        }
        catch{}
      }
    }
    let check: any;
    // This function required mostly for safari due to slow rendering of searchbox JS sometimes
    check = setInterval(checkTrigger, 700);
  },[])

  return (
    <div id='replace-main-header-container'></div>
  );
}
export default Header;