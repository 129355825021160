import {useState,useEffect} from 'react';
import info_icon from '../../assets/info_icon.svg';
import { properties, getLocale, getCountryFromLocale, getPriceText, getButtonText, getEdition } from '../../common/properties';
import { ProductType } from '../../types/commonTypes';

interface ProductCardProp  {
  product: ProductType
}

interface ActiveVariationType  {
  publish_date?:number,
  purchaseOption_group?:string[],
  price?:number
}   

function ProductCard({ product }: ProductCardProp) {
  let defaultImage = properties.defaultImage;
  let [tax,setTax] = useState("");
  let [content, setContent] = useState("");
  let [imgSrc, setImgSrc] = useState(product.data?.image_url == undefined ? defaultImage : product.data?.image_url);
  
  let enableFeatureLabelFlag = false;
  // Featured label for featured products
  if(product.data.facets.some(facet => facet.name === "featured")) {
    enableFeatureLabelFlag = true;
  }
  let locale = getLocale();
  let country = getCountryFromLocale();
  let vatInfoCountry = properties.vatInfo.country;
  let ptiInfoCountry = properties.ptiInfo.country;
  let authorList = product.data.author;
  let activeVariations: ActiveVariationType = {};
  if(product.variations_map?.NO && product.variations_map?.NO.ACTIVE){
    activeVariations = product.variations_map?.NO.ACTIVE;
  }
  let publish_date = String(activeVariations.publish_date).trim();
  let publish_date_formatted = '';
  if(publish_date != "null" && publish_date != 'undefined' && publish_date != ''){
    if(publish_date == (properties.blankPublicationDate).toString()){
      publish_date = "";
    }
    else{
      let match = publish_date.match(/.{1,4}/g);
      if(match !== null)
      {
        let month = Number(match[1])-1;
        publish_date_formatted = properties.monthShortNames[month]+" "+match[0];
      }
    }
  }
  let product_format:string[] = [];
  if(activeVariations != undefined && activeVariations.purchaseOption_group != undefined){
    let purchaseOption_product_format = activeVariations.purchaseOption_group.join(",");
    if(purchaseOption_product_format.includes("|")) {
      product_format = purchaseOption_product_format.replaceAll("|", ",").split(",").sort();
    } else {
      product_format = activeVariations.purchaseOption_group.sort();
    }    
  }
  let unique_product_format = [...new Set(product_format)];
  let formatList = unique_product_format.join(", ");
  let price = '';
  if(activeVariations != undefined && activeVariations.price != undefined){
    price = (activeVariations.price).toFixed(2);
  }
  let formatEdition = product.data['edition'] && getEdition(product.data['edition'])
  let baseUrl = process.env.REACT_APP_BASE_URL;
  let PDPLink = baseUrl+"/"+locale+product.data.url;
  let priceText = getPriceText(price,country);
  let showPriceText = (price !== 'NaN' && price != '') ? (priceText.link != '' ? priceText.msg : priceText.msg) : '';
  let buttonText = getButtonText(showPriceText);
  let imageCoverClass = `product-image ${(imgSrc == defaultImage || imgSrc == undefined) ? "grey-bcground" : ""}`;

  const handleImageError = () => {
    setImgSrc(defaultImage);
  };

  const handleClick = () =>{
    window.location.href = PDPLink;
  }

  useEffect(()=>{
    if(vatInfoCountry.includes(country))
    {
      setTax(properties.vatInfo.info);
      setContent(properties.vatInfo.content);
    }else if(ptiInfoCountry.includes(country))
    {
      setTax(properties.ptiInfo.info);
      setContent(properties.ptiInfo.content);
    }
  },[country])

  return (
    <div
      className="product-card"
      data-cnstrc-item-id={ product.data.id }
      data-cnstrc-item-name={ product.value }
      data-cnstrc-item-variation-id={ product.data?.variation_id }
      data-testid="productCard"
    >
      <div className="product-card-inner">
        <div className='image-data-container'>
          <div className={imageCoverClass}>
            <a href={ PDPLink }>
            <img
            data-testid="product-image"
            className="item-image"
            src={ imgSrc }
            alt={ product.value }
            onError={ handleImageError }
            onLoad={ (event) => {(event.target as HTMLElement).className = "item-image"} }
          />
            { /* Featured label for featured products */
              enableFeatureLabelFlag && <span className="product-featured-label" data-testid="product-featured-label">{"Featured"}</span>
            }
            </a>
          </div>
          <div className='product-detail'>
            <div className='product-title' data-testid={`product-title-${product.value}`}> 
              <h3><a href={ PDPLink } dangerouslySetInnerHTML={{__html:product.value}} /></h3>
            </div>  
            <div className='product-dt' data-testid="product-dt">{formatEdition && `${formatEdition} Ed.`}{formatEdition && publish_date_formatted && ' | '}{publish_date_formatted}</div>
            <div className='product-authors' dangerouslySetInnerHTML={{__html:authorList}} data-testid="product-authors" />
          </div>
        </div>  
        <div className="product-other-details">
          <div className="product-meta-data">
            <div className='product-options d-md-block' data-testid="product-options">{formatList}</div>
            <div className='product-price' data-testid="product-price">{showPriceText}</div>
          </div>
          <div className="learn-more">
              {(vatInfoCountry.includes(country) || ptiInfoCountry.includes(country)) && 
                <div className="tooltip-container">
                  <span className="tax-text" data-testid="tax-title">{tax}</span>
                  <div className="info-image-container">
                    <img src={info_icon} alt="info icon"/>
                    <div className="tax-info" data-text={tax} data-testid="tax-content">{content}</div>
                  </div>
                </div>
              }
             <button className="learn-more-btn" onClick={handleClick} data-testid="product-card-button">{buttonText}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;