import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchBrowseResults, fetchProducts } from '../utils';
import { loadStatuses } from '../utils/constants';
import { properties, getCountryFromLocale } from '../common/properties';
import SearchResult from './SearchResult';
import { FacetType,SortOptionsType } from '../types/commonTypes';

function useFirstRender() {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
}

function SearchResultPage() {
  const isFirstRender = useFirstRender();
  const location = useLocation();
  let country = getCountryFromLocale();
  const [page, setPage] = useState<number>(1);
  const [loadStatus, setLoadStatus] = useState(loadStatuses.LOADING);
  const [items, setItems] = useState([]); 
  const [totalResults, setTotalResults] = useState(0);
  const [facets, setFacets] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [publishDateOptions, setPublishDateOptions] = useState({});
  const [error, setError] = useState<any[]>([]);
  const numResultsPerPage = properties.itemsPerPage;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let facetList = properties.facetList;
  const sortOptionList = properties.sortOptionList;
  const [selectedSortOption, setSelectedSortOption] = useState<SortOptionsType|undefined>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const publishDateFacetName = properties.publishDateFacetName.replace('country',country);

  function reportIsMobile() {
    setScreenWidth(window.innerWidth);
  }
  
  window.onresize = reportIsMobile;

  useEffect(() => {
    if(!params.has('pq')){
      params.set("pq", "");
      navigate({ search: params.toString() })
    }
    let df1 = properties.dynFacetList.map((df) => {
      return df.replace('country',country);
    })
    facetList.push(...df1);
    facetList = [...new Set(facetList)]
    // let ds1 = properties.dynSortOptionList.map((ds) => {
    //   return ds.replace('country',country);
    // })
    // sortOptionList.push(...ds1);
    
    fetchProductsFromAPI();
  }, [location]);

  const fetchProductsFromAPI = async () => {
    setLoadStatus(loadStatuses.LOADING);

    try {
      let response;
      let curpage = params.has('page') ? Number(params.get('page')) : 1;
      if(params.get("pq")?.replace(/\s+/g, ' ') === " "){
        response = await fetchBrowseResults("group_id","all",numResultsPerPage,country)
      }
      else{
        response = await fetchProducts(numResultsPerPage,country);
      }

      if(window.adobeDataLayer && sessionStorage.getItem('searchMade'))
        {
          window.adobeDataLayer.push({
            "event":"searchProduct",
            "searchDetails":
            {
              "searchTerm":params?.get("pq"),
              "searchResultCount":response?.total_num_results.toString()
            }
            })
          sessionStorage.removeItem('searchMade')  
        }

      let publishDateOption:{[key:string]:string} = {};

      setLoadStatus(loadStatuses.LOADING);
      setItems(response.results);
      let tempSortOptions = response.sort_options.map((item:SortOptionsType) => ({ ...item, id: `${item.sort_by}_${item.sort_order}` }));
      let selSortOption = selectedSortOption;
      setSortOptions(tempSortOptions.filter(function(sort:SortOptionsType) {
        if(sortOptionList.includes(sort.sort_by)){
          if(sort.status == 'selected'){
            selSortOption = sort;
          }
          return sort;
        }
      }));
      setSelectedSortOption(selSortOption);
      setFacets(response.facets.filter(function(facet:FacetType) {
        if(facetList.includes(facet.name)){
          if(facet.name == publishDateFacetName){
            let pdo = facet.options;
            pdo.map((pdop) => {
              publishDateOption[pdop.value] = pdop.display_name;
            })
          }
          if(facet.options.length == 1){
            let dp = facet.options[0].display_name;
            if(dp != "")
              return facet;
          }
          else
          return facet;
        }
      }));
      setPublishDateOptions(publishDateOption);
      setTotalResults(response.total_num_results);
      setPage(curpage);
      setLoadStatus(loadStatuses.SUCCESS);
    } catch (e) {
      setLoadStatus(loadStatuses.FAILED);
      setError([e]);
    }
  };

  const loadMoreProductsAndSetState = async (event: { selected: number }) => {
    const currPage = (event.selected);
    
    params.set("page", (currPage+1).toString());
    navigate({ search: params.toString() });
  };
    return (
        <SearchResult 
          items={ items }
          loadStatus={ loadStatus }
          facets={ facets }
          publishDateOptions = {publishDateOptions}
          sortOptions={ sortOptions }
          selectedSortOption={ selectedSortOption }
          totalResults={ totalResults }
          numResultsPerPage={ numResultsPerPage }
          error={ error }
          page={ page }
          screenWidth={ screenWidth }
          loadMoreProducts={ loadMoreProductsAndSetState }
          facetList={facetList}
          />
    );
}

export default SearchResultPage;
