import React, {useRef, useState, Suspense, useEffect}  from 'react';
import { loadStatuses } from '../utils/constants';
import SearchResultPanel from '../components/searchResultPage/searchResultPanel';
import { properties, getLocale, getCountryFromLocale } from '../common/properties';
import { parseUrlParameters } from '../utils';
import LoadingSpinner from "../components/loadingSpinner";
import { FacetType, ProductType, SortOptionsType,ParametersType } from '../types/commonTypes';
const FacetPanel = React.lazy(() => import('../components/searchResultPage/facetPanel'));


function useFirstRender() {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
}

interface searchResultProp {
  items: ProductType[],
  loadStatus : number,
  facets: FacetType[],
  publishDateOptions:{
    [key:string]:string
  },
  error:any[]
  sortOptions:SortOptionsType[],
  selectedSortOption:SortOptionsType|undefined,
  totalResults: number,
  numResultsPerPage: number,
  page: number,
  screenWidth:number,
  loadMoreProducts:(event:{selected:number})=>void,
  facetList:string[],
}

function SearchResult(props:searchResultProp) {
  const {
    items,
    loadStatus,
    facets,
    publishDateOptions,
    sortOptions,
    selectedSortOption,
    totalResults,
    numResultsPerPage,
    page,
    screenWidth,
    loadMoreProducts,
    facetList
  } = props;

  const isFirstRender = useFirstRender();
  
  const isLoading = loadStatus === loadStatuses.LOADING;
  const numItems = items.length;
  const queryParams = new URLSearchParams(window.location.search);
  const term = queryParams.get("pq");
  const encodedTerm: string = encodeURIComponent(term ?? '');
  const locale = getLocale();
  const contentURL = process.env.REACT_APP_BASE_URL+"/"+locale+properties.contentURL.replace("{cq}", encodedTerm).replace("{pq}",encodedTerm);
  const [filterButtonToggle, setFilterButtonToggle] = useState(false);
  let country = getCountryFromLocale();
  const [selectedFiltersFromResponse, setSelectedFiltersFromResponse] = useState<string[]>([]);
  const header_msg = () => {
    if (term?.trim().length === 0 && selectedFiltersFromResponse.length > 0) {
      const { parameters: { filters } }:{parameters:ParametersType} = parseUrlParameters();
      let firstQueryParam = Object.values(filters)[0]?.[0];
      if(firstQueryParam) return properties.publicationDateRegex.test(firstQueryParam) ? properties.searchForPublicationDateHeaderMessage : `${properties.commonSearchResultsFor}${firstQueryParam}"`;
    }
    return `${properties.commonSearchResultsFor}${term}"`;
  }
  const fetchSelectedFacetsFromResponse = () => {
    // This function is added to remove the non checked facet filters that apprearing on top of result page (DCPR-1955)
    let selectedFacets:string[] = [];
    facets.map(facet => facet.options
      .filter(item => item.status === "selected")
      .forEach(facetValue => selectedFacets.push(facetValue.value)));
    setSelectedFiltersFromResponse(selectedFacets);
  }

  const onFilterButtonToggle = () => {
    const facetPanelWrapper = document.getElementsByClassName('facet-panel-wrapper')[0] as HTMLElement;
    if (filterButtonToggle) {
      document.body.classList.remove('overflow-hidden');
      facetPanelWrapper.style.display = 'none';
      setFilterButtonToggle(false);
    } else {
      document.body.classList.add('overflow-hidden');
      facetPanelWrapper.style.display = 'block';
      setFilterButtonToggle(true);
    }
  };

  useEffect(() => {
    if(!isFirstRender && (loadStatus !== loadStatuses.LOADING)){
      feedDataLayer();
      if(totalResults > 0){
        const { parameters: { filters } } = parseUrlParameters();
        let facetChecked : string[]= [];
        Object.entries(filters).forEach(([filterGroup, filterOptions]) => {
          if (filterGroup !== 'group_id') {
            facetChecked.push(filterGroup.toUpperCase()+'='+String(filterOptions));
          }
        });
        let facetAnalytics = facetChecked.join('|');
        window.digitalData['search']['filters']=facetAnalytics;
        fetchSelectedFacetsFromResponse();
      }
    }
  }, [facets])

  function feedDataLayer(){
    let productSearchPage :{[key:string]:string|object}= {};
    if(totalResults > 0){
      productSearchPage = {
        'event':'search results',
        'page': {
          'pageName':'www:wil:'+country.toLowerCase()+':products:search-page',
          'section':'products',
          'type': 'search results'
        },
        'search': {
            'type':'products',
            'term':term,
            'filters':'',
            'numSearchResults':totalResults
        }
      }
    }
    else{
      productSearchPage = {
        'event':'search results',
        'formName': '',
        'page': {
            'pageName':'www:wil:'+country.toLowerCase()+':search-empty',
            'section':'products',
            'type': 'search results'
        },
        'search': {
            'type':'',
            'term':term,
            'filters':'',
            'numSearchResults':'0'
        }
      }
    }
    for (var key in productSearchPage) {
      window.digitalData[key] = productSearchPage[key];
    }
  }

  return (
    <div className='search-resul-page container-fluid'>
      {(!isLoading && loadStatus === loadStatuses.SUCCESS) &&
      <div className='search-result-container-header'>
        <div className={'search-result-content-tab-header '+ ((term) ? '' : 'no-search-query')}>
          <div className="nav" id="nav-tab" role="tablist">
            <button data-testid="product-tab" className="nav-link active" id="nav-products-tab" data-bs-toggle="tab" data-bs-target="#nav-products" type="button" role="tab" aria-controls="nav-products" aria-selected="true"><span>Products</span></button>
            {(term != '') && <a data-testid="content-tab" className="nav-link" href={contentURL}>Content</a>}
          </div>
        </div>
        <div className='search-result-page-header'>{(term != '' && numItems>0) && ( <h1 data-testid="header-msg" dangerouslySetInnerHTML={{__html:header_msg()}}></h1> )}</div>
      </div>
      }
      {
        ((!isFirstRender && loadStatus !== loadStatuses.FAILED && numItems>0) || (isFirstRender && !isLoading && (loadStatus === loadStatuses.SUCCESS && numItems >= 1)))
          && (
            <>
      <div className='search-result-container'>
        <div className='loading-overlay-wrapper'>
          {isLoading &&
          <div className='loading-overlay'>
            <div className='loading-overlay-content'>
              <LoadingSpinner loading={isLoading}/>
            </div>
          </div>
          }
          <div className='product-list-container'>
            <Suspense fallback={(screenWidth < properties.breakpoints["min-lg"]) ? null : <div>Loading Filters...</div>}>
              <FacetPanel facets={facets} screenWidth={screenWidth} onFilterButtonToggle={onFilterButtonToggle} facetList={facetList}/>
            </Suspense>
            <SearchResultPanel
            selectedFiltersFromResponse={selectedFiltersFromResponse}
            items={ items }
            publishDateOptions={ publishDateOptions }
            sortOptions={ sortOptions }
            selSortOption={ selectedSortOption }
            totalResults={ totalResults }
            numResultsPerPage={ numResultsPerPage }
            page={ page }
            loadMoreProducts={ loadMoreProducts }
            onFilterButtonToggle={onFilterButtonToggle}
            facetList={facetList} 
            />
          </div>
        </div>
      </div>
      </>
      )}
      {!isLoading && (loadStatus === loadStatuses.FAILED || numItems === 0) && (
        <div className="no-result-found">
          <p data-testid="no-result">{properties.noResultFoundText}</p>
        </div>
      )}
      {isFirstRender && (loadStatus === loadStatuses.LOADING) && (
        <div className="no-result-found">
          <p data-testid="loading-result">Loading search results ... </p>
        </div>
      )}
    </div>
  );
}

export default SearchResult;