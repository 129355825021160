interface Properties {
  facetList: string[],
  dynFacetList: string[],
  publishDateFacetName: string,
  sortOptionList: string[],
  itemsPerPage: number,
  contentURL: string,
  getCurrencyFromLocale: { [key: string]: string },
  getDefaultLocale: string,
  priceText: [number, string, number][],
  defaultImage: string,
  blankPublicationDate: number,
  monthShortNames: string[],
  enablePlaceHolderInSRP: boolean,
  vatInfo: { country: string[], info: string, content: string },
  ptiInfo: { country: string[], info: string, content: string },
  publicationDateRegex: RegExp,
  searchForPublicationDateHeaderMessage: string,
  commonSearchResultsFor: string,
  noResultFoundText: string,
  marketingSlotURL: string,
  headerFooterURL: string,
  breakpoints: { [key: string]: number }
}

export const properties: Properties = {
  facetList: ["featured", "subjects", "author", "format", "purchaseOption", "brand"],
  dynFacetList: ["publish_date_filter_country"],
  publishDateFacetName: "publish_date_filter_country",
  sortOptionList: ["relevance", "item_name", "author"],
  //dynSortOptionList: ["publish_date_display_country"],
  itemsPerPage: 15,
  contentURL: "/content-search?cq={cq}&pq={pq}",
  getCurrencyFromLocale: { US: "$", AU: "AUD $", BE: "€", BR: "$", CA: "CAD $", CN: "$", DK: "€", FR: "€", DE: "€", HK: "$", IN: "$", IE: "€", IT: "€", JP: "$", MX: "$", NL: "€", NZ: "AUD $", NO: "€", SG: "$", KR: "$", ES: "€", SE: "€", CH: "€", AE: "£", GB: "£" },
  getDefaultLocale: "en-us",
  priceText: [[12345678.90, "See purchase details", 1], [90123456.78, "Price available upon request", 0]],
  defaultImage: process.env.PUBLIC_URL + "/wiley_logo2.png",
  blankPublicationDate: 209912,
  monthShortNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  enablePlaceHolderInSRP: true,
  vatInfo: { country: ["BE", "DK", "FR", "GB", "IT", "IE", "NL", "NO", "AE", "ES", "SE"], info: "VAT Information", content: "Please note ebook prices are sales VAT inclusive. All other prices are sales VAT exclusive. You, the buyer, are responsible for any import VAT charges required by your country. Any applicable charges will be made by the carrier." },
  ptiInfo: { country: ["AU", "NZ"], info: "Price and Tax Information", content: "Please note all Recommended Retail Prices (RRPs) are in Australian dollars and are subject to change without notice. Goods and Services Tax (GST) is included in the RRP for orders within Australia and is excluded on international orders." },
  publicationDateRegex: /^(?:[1-9]\d{0,3}|9999)$/,
  searchForPublicationDateHeaderMessage: "Search Results for Publication Date",
  commonSearchResultsFor: 'Search Results for "',
  noResultFoundText: "We couldn't find any matching results. Please restart your search.",
  marketingSlotURL: process.env.REACT_APP_AEM_RESOURCE_BASE_URL + '/content/experience-fragments/wiley-marketing-slots/global/global-api/master.api.html/search/',
  headerFooterURL: process.env.REACT_APP_AEM_RESOURCE_BASE_URL + '/content/experience-fragments/wiley-dotcom/{country}/{lang}/consumer/site/global/api/master.xfheaderfootercontent.html',
  breakpoints: {"min-lg": 1081}
}
export const getLocale = () => {
  let curLocale = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
  return curLocale;
}
export const getCountryFromLocale = () => {
  let curLocale = getLocale();
  let locale = curLocale.split("-");
  const country = locale[1].toUpperCase();
  return country;
}
export const getQueryParam = () => {
  const queryParams = new URLSearchParams(window.location.search);
  let searchTerm = queryParams.get("pq");
  return searchTerm;
}
export const getPriceText = (price: string, country: string) => {
  let resArr = { msg: '', link: '' };
  if (price == (properties.priceText[0][0]).toFixed(2)) {
    resArr.msg = properties.priceText[0][1];
    if (properties.priceText[0][2] == 1) {
      resArr.link = 'yes';
    }
  }
  else if (price == (properties.priceText[1][0]).toFixed(2)) {
    resArr.msg = properties.priceText[1][1];
    if (properties.priceText[1][2] == 1) {
      resArr.link = 'yes';
    }
  }
  else {
    resArr.msg = 'Starting at ' + properties.getCurrencyFromLocale[country] + price;
  }
  return resArr;
}

export const getButtonText = (showPriceText: string) => {
  return showPriceText === properties.priceText[1][1] ? "Request A Quote" : "Learn More";
}
export const loadImage = (variable: string) => {
  var image = new Image();
  var url_image = './ImageFolder/' + variable + '.jpg';
  image.src = url_image;
  if (image.width == 0) {
    return `<img src='./ImageFolder/defaultImage.jpg'>`;
  } else {
    return `<img src='./ImageFolder/` + variable + `.jpg'`;
  }
}
export const searchQueryIsISBN = (query: string) => {
  if (query.length >= 13 && (/^978|979/.test(query))) {
    return true;
  }
  return false;
}

export const getEdition = (edition: number) => {
  if (edition >= 99) {
    return null
  }
  const digits = [edition % 10, edition % 100],
    ordinals = ['st', 'nd', 'rd', 'th'],
    oPattern = [1, 2, 3, 4],
    tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? edition + ordinals[digits[0] - 1]
    : edition + ordinals[3];
};

export const LoadExternalScript = (url: string) => {
  const externalScript = document.createElement("script");
  externalScript.type = "text/javascript";
  externalScript.src = url;
  if (url.includes('searchLibrary')) {
    externalScript.id = 'searchLibrary';
  }
  document.getElementsByTagName("head")[0].appendChild(externalScript);
};

export const LoadExternalStyle = (url: string) => {
  const externalStyle = document.createElement("link");
  externalStyle.rel = "stylesheet";
  externalStyle.type = "text/css";
  externalStyle.href = url;
  document.body.appendChild(externalStyle);
};

export const LoadInlineScript = (script: any) => {
  const externalScript = document.createElement("script");
  const scriptText = document.createTextNode(script);
  externalScript.appendChild(scriptText);
  document.getElementsByTagName("head")[0].appendChild(externalScript);
};