import React from 'react';
import { SortOptionsType } from "../../types/commonTypes";


interface MobsortOptionsProp {
  sortOptions:SortOptionsType[],
  onChangeSorting: (event: React.ChangeEvent<HTMLInputElement>) => void,
  selectedItem:SortOptionsType | undefined
}

function MobsortOptions(props:MobsortOptionsProp) {
  const { 
    sortOptions,
    onChangeSorting,
    selectedItem
  } = props;

  if (sortOptions?.length > 0) {
    return (
        <ul className='sort-panel-options p-0' data-testid="mobSortOptions">
        {sortOptions.map((sortOption,index) => 
          <li key={ sortOption.id } className='d-flex justify-content-between' data-testid="mob-sort-option"><label>{sortOption.display_name}</label><label className='mb-3'><input type='radio' name='sortedBy' checked={(selectedItem == undefined) ? (sortOption.status == 'selected') : (selectedItem.id == sortOption.id)} onChange={ onChangeSorting } value={sortOption.id}/><span className='checkradio'></span></label></li>
        )}
        </ul>
    );
  }
  return null;
}

export default MobsortOptions;
