import React, { useEffect, useState }  from 'react';
import {useNavigate,useSearchParams} from 'react-router-dom';
import ProductCard from './ProductCard';
import SortOptions from './sortOptions';
import SortPanelMobile from './sortPanelMobile';
import ReactPaginate from 'react-paginate';
import { parseUrlParameters } from '../../utils';
import { BsSliders } from 'react-icons/bs';
import { FaTimes } from 'react-icons/fa';
import { ProductType, SortOptionsType,ParametersType } from '../../types/commonTypes';
import { properties } from '../../common/properties';


interface SearchResultPanelProps {
    items:ProductType[],
    publishDateOptions:{
      [key:string]:string
    },
    sortOptions:SortOptionsType[],
    selSortOption:SortOptionsType|undefined,
    totalResults:number,
    numResultsPerPage:number,
    page:number,
    loadMoreProducts : (event:{selected:number})=>void,
    onFilterButtonToggle: ()=>void,
    selectedFiltersFromResponse: string[],
    facetList:string[],
}


function SearchResultPanel(props:SearchResultPanelProps) {
  const {
    items,
    publishDateOptions,
    sortOptions,
    selSortOption,
    totalResults,
    numResultsPerPage,
    page,
    loadMoreProducts,
    onFilterButtonToggle,
    selectedFiltersFromResponse,
    facetList,
  } = props;
  const [pageCount, setPageCount] = useState(0);
  const [checked, setChecked] = useState<{[key:string]:string}>({});
  const navigate = useNavigate();
  const { parameters: { filters } }:{parameters:ParametersType} = parseUrlParameters();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSortOption, setSelectedSortOption] = useState<SortOptionsType | undefined>();
  useEffect(() => {
    setTimeout(() => window.scrollTo({top: 0, behavior: 'smooth'}),500);
  }, [page]);
  
  useEffect(() => {
    setPageCount(Math.ceil(totalResults / numResultsPerPage));
    const tmpChecked: { [key: string]: string } = {};
    Object.entries(filters).forEach(([filterGroup, filterOptions]) => {
      
      if (filterGroup !== 'group_id') {
        if(typeof filterOptions === "object")
        {
          filterOptions.forEach((facetOption:string) => {
            tmpChecked[`${filterGroup}|${facetOption}`] = facetOption;
          });
        }
      }
    });
    setChecked(tmpChecked);
    setSelectedSortOption(selSortOption);
  }, [items,numResultsPerPage,totalResults,selSortOption]);
  const clearFilter = (event:React.MouseEvent<HTMLButtonElement>) => {
    let checkID = event.currentTarget.getAttribute("data-checkboxid");
    if(checkID !== null)
    {
      var filterElem =  document.getElementById(checkID);
      if (typeof(filterElem) != 'undefined' && filterElem != null){
        document.getElementById(checkID)?.click();
      }
      else{
        const [facetGroup, facetOption] = checkID.split('|');
        const filterKey = `filters[${facetGroup}]`;
        const existingValues = searchParams.get(filterKey);
        let newValue;
        searchParams.delete(filterKey);
        newValue = existingValues?.split('|').filter((val) => val !== facetOption).join('|');
        if(newValue){
          searchParams.set(filterKey, newValue);
        }
        searchParams.delete('page');
        searchParams.get("filters["+facetGroup+"]") === "" && searchParams.delete("filters["+facetGroup+"]");
        navigate({ search: searchParams.toString() });
      }
    }
  }
  const clearAll = () => {
    Object.entries(checked).forEach(([facet_value]) => {
      let fvalue = facet_value.split("|");
      searchParams.delete('filters['+fvalue[0]+']')
    })
    searchParams.set('page','1');
    setSearchParams(searchParams);

    facetList.forEach((facet)=>{
      const inputFacet = document.querySelector(`.text-${facet}`) as HTMLInputElement
      if(inputFacet)
      {
        inputFacet.value = ''
        const event = new Event('keyup',{bubbles:true})
        inputFacet.dispatchEvent(event)
      }
    })
    
    const element1:NodeListOf<Element> = document.querySelectorAll('.accordion-button');
    for (const el1 of element1) {
        el1.classList.add('collapsed');
    }
    const element2:NodeListOf<Element> = document.querySelectorAll('.accordion-collapse');
    for (const el2 of element2) {
        el2.classList.remove('show');
    }
  }

  const renderPrevButton = ()=>{
    return(
      <span data-testid="prev-button">&lt; Prev</span>
    )
  }

  const renderNextButton = ()=>{
    return(
      <span data-testid="next-button">Next &gt;</span>
    )
  }
  
  return (
    <div className='search-result-wrapper'>
        <div className="tab-content" id="nav-tabContent">
            <div
            className="tab-pane fade show active"
            id="nav-products"
            role="tabpanel"
            aria-labelledby="nav-products-tab">
            <div className='search-resul-header'>
                <div className='sort-wrapper'>
                  <SortOptions sortOptions={ sortOptions } setSelectedSortOption={setSelectedSortOption}/>
                </div>
            </div>
            <div className='search-resul-mob-btn-div'>
              <div className='filter-btn-mobile search-resul-mob-btn'>
                <button
                type="button"
                onClick={ () => onFilterButtonToggle() }
                >
                <BsSliders />
                <span className="mx-2">Filters {selectedFiltersFromResponse.length > 0 ? '('+selectedFiltersFromResponse.length+')' : ''}</span>
                </button>
              </div>
              <SortPanelMobile sortOptions={ sortOptions } selectedSortOption={selectedSortOption}/>
            </div>
            <div className="search-resul-wrap">
                {Object.keys(checked).length > 0?
                <div className='search-controls-removable_filter_pills selected-filters' data-testid="search-controls-container">
                <ul className='search-controls-removable_filter_pill'>
                    {Object.entries(checked).map(([facet_id,facet_value]) => 
                      selectedFiltersFromResponse.includes(facet_value) && <li key={facet_value} data-testid="selected-filter-item"><span><span data-testid="facet-value" dangerouslySetInnerHTML={{__html:publishDateOptions[facet_value]?publishDateOptions[facet_value]:facet_value}} /><button data-testid="selected-facet-clear" onClick={clearFilter} data-checkboxid={ `${facet_id}` }><FaTimes className="fa-icon"/></button></span></li>
                    )}
                    <li key='clearfilter' className='clearfilter'><span><button type='button' className='clearallfilter' onClick={clearAll} data-testid="clear-all-applied-filter">Clear All</button></span></li>
                </ul>
                </div>
                :""}
                <div
                id="search-results"
                className="search-resul-inner-wrap"
                data-cnstrc-search
                data-cnstrc-num-results={ totalResults }
                data-testid="search-result-container"
                >
                {items.map((item, index) =>
                <React.Fragment key={item.data.id}> 
                  {(properties.enablePlaceHolderInSRP) && (<div id={`Search-Target-Placeholder-${index+1}`} data-testid="search-target-placeholder" className="placeholder-srp"/>)}
                  <ProductCard product={ item } />
                </React.Fragment>)}
                {(properties.enablePlaceHolderInSRP) && <div id={`Search-Target-Placeholder-${items.length+1}`} data-testid="search-target-placeholder" className="placeholder-srp"/>}
                </div>
            </div>
            {(pageCount >= 1) ?
            <div className="paginate-wrapper">
                <span className='page-display' data-testid="page-display">Displaying: {(((page-1) * numResultsPerPage)+1).toLocaleString()} - {((page * numResultsPerPage)<totalResults?(page * numResultsPerPage):totalResults)} of {totalResults.toLocaleString()}</span>
                <ReactPaginate
                containerClassName="pagination"
                activeClassName="active"
                breakLabel="..."
                nextLabel={renderNextButton()}
                onPageChange={loadMoreProducts}
                pageRangeDisplayed={(page === 1 || page === pageCount) ? 3 : 2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                forcePage={page-1}
                previousLabel={renderPrevButton()}
                renderOnZeroPageCount={()=>null}
                />
            </div>
            : ''}
            </div>
        </div>
    </div>
  );
}

export default SearchResultPanel;
