import React from "react";
import { Link } from "react-router-dom";

interface crumbType {
  name:string,
  path:string
}

interface BreadcrumbsProps {
   crumbs : crumbType[]
}

const Breadcrumbs = ({crumbs}:BreadcrumbsProps) => {
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }
  return (
    <div className="page-breadcrumb container-fluid">
      <ul className="breadcrumb" data-testid="breadcrumb">
        {crumbs.map(({ name, path }, key) =>
          key + 1 === crumbs.length ? (
            <li key={key} data-testid="current-page"><span key={key}>
              {name}
            </span></li>
          ) : (key === 0 ? (
            <li key={key} data-testid="home-page"><a key={key} href={process.env.REACT_APP_BASE_URL + path}>
              {name}
            </a></li>
          ) : (
            <li key={key} data-testid="previous-pages"><Link key={key} to={path}>
              {name}
            </Link></li>
          ))
        )}
      </ul>
    </div>
  );
};
export default Breadcrumbs;