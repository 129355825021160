import classNames from "classnames";
import { FaCircleNotch } from "react-icons/fa";

interface LoadingSpinnerProp {
  loading:boolean
}

const spinnerProps = {
  spin:"true",
  className:"spinner"
}

const LoadingSpinner = (props:LoadingSpinnerProp) => (
  <div className={classNames("-loading", { "active -active": props.loading })}>
    <div id="spinner" data-testid="loading-icon">
        <FaCircleNotch {...spinnerProps}/>
    </div>
    <span className="loading-text" data-testid="loading-text">Loading...</span>
  </div>
);

export default LoadingSpinner;